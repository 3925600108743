import React from 'react';
import styled from 'styled-components';
import { HeadingOne } from '@marty-js/design/src/atoms/h1';
import { NavLink } from '@marty-js/sdk/src/atoms/nav-link';
import { useTranslation } from '@marty-js/design/src/utils/translation';
import { useLayoutDataByUrlQuery } from '@marty-js/api-sdk/services/layoutData';
import { useSetMetaTitle } from '@marty-js/sdk/src/utils/metaSeoContext';
import { DefaultLayout } from '../modules/layout/default-layout';
import { FullSizeContent } from '../modules/layout/FullSizeContent';

const StyledLink = styled(NavLink)`
  margin-left: 4px;
  text-decoration: none;
  color: var(--theme-color-primary);
  font-weight: bold;
`;

const Paragraph = styled.p`
  margin-top: 15px;
  margin-bottom: 15px;
`;

const MainTitleRed = styled(HeadingOne)`
  color: var(--theme-color-primary);
  font-size: 55px;
  margin-bottom: 14px;
`;

const ImportantText = styled.p`
  margin-top: 10px;
  margin-bottom: 10px;
`;

export default function Custom404() {
  const t = useTranslation();
  const { layoutData } = useLayoutDataByUrlQuery({
    variables: { url: '/' },
    pollInterval: 60000,
  });
  useSetMetaTitle(t('errorPage.404.metaSeo.title'));

  return (
    <DefaultLayout data={layoutData?.data ? JSON.parse(layoutData?.data) : null}>
      <FullSizeContent>
        <MainTitleRed>404</MainTitleRed>
        <h2>{t('errorPage.404.subTitle')}</h2>
        <Paragraph>{t('errorPage.404.text')}</Paragraph>
        <Paragraph>
          <StyledLink href="/">{t('errorPage.404.backLink')}</StyledLink>
        </Paragraph>
        <ImportantText>{t('errorPage.404.footerText.0')}</ImportantText>
        <ImportantText>{t('errorPage.404.footerText.1')}</ImportantText>
        <Paragraph>
          <img src="https://www.clubic.com/assets/bundles/m6clubic/img/notFoundMultipla.png" alt="" />
        </Paragraph>
      </FullSizeContent>
    </DefaultLayout>
  );
}

export const getStaticProps = async () => {
  return {
    revalidate: 60,
    props: {},
  };
};
